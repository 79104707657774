import React, { useState } from 'react'
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

import ProductCard from "../products/productCard"

const AssocProduct = ({ assocProducts }) => {

  const [currentItem, setCurrentItem] = useState(assocProducts[0]);

  return (
      <section className="module_assoc_prod grid-std grid-flow-row my-20 xl:my-40 gap-y-8">
        <div className="col-start-2 col-end-8">
            <p>{assocProducts.length > 1 ? 'Associated products' : 'Associated product'}</p>
            <p className="mt-2 text-lg lg:text-2xl">{currentItem && currentItem.title}</p>
        </div>
        <div className="col-start-2 col-end-8 lg:col-start-17 lg:col-end-26">
            {assocProducts.length > 1 ? (
              <>
              <Swiper
                modules={[A11y, Pagination]}
                pagination={{
                  el: '.assoc_prod-pagination',
                  type: 'bullets',
                }}                             
                slidesPerView={1}
                spaceBetween={20}
                loop={true}      
                onSlideChange={(swiper) => setCurrentItem(assocProducts[swiper.realIndex])}  
              >
                {assocProducts.map((product) => (
                  <SwiperSlide><ProductCard {...product}  greenMode={true} /></SwiperSlide>
                ))}
              </Swiper>
              <div className="assoc_prod-pagination"></div>
              </>
            ) : (
              <ProductCard {...assocProducts[0]}  greenMode={true} />
            )}

        </div>                      
      </section>      
  )
}

export default AssocProduct