import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import ModuleZone from '~moduleZone';
import ProjectBanner from "../components/projects/projectBanner"
import ProjectInformation from "../components/projects/projectInformation"
import AssocProduct from '../components/projects/assocProduct';
import ProjectCta from '../components/projects/projectCta';
import ProjectFooter from '../components/projects/projectFooter';

const ProjectTemplate = ({ pageContext, data: { page } }) => {  
  
  const { 
    pageModules,
    headerTheme,
    myPorterCta,    
    heroImg,
    heroImgUrl,
    heroVideo,
    bannerStyle,
    description,    
    touchColor,
    title,
    client,
    year,
    deliverables,
    certificationOptions,
    features,
    sustainabilityOptions,
    assocProducts,
    hidden,
    seo } = page || {}  

  const { 
    setMyPorter, 
    setFooterHidden, 
    setPageFeatureColor, 
    setHeaderDark, 
    setPageBackground, 
    setFooterForms, 
    setFooterTheme 
  } = useSiteState();

  useEffect(() => {
    
    document.documentElement.setAttribute('data-theme', `theme-grey-60`);
    document.documentElement.style.setProperty(`--doc-bg`, '#999999');      

    setPageBackground('#999999');    
    setMyPorter(myPorterCta === true ? true : false)
    if (touchColor) {
      setPageFeatureColor(touchColor.hex);
    }

    setFooterForms(false)
    setFooterTheme('theme-none')
    setHeaderDark(false)
    setFooterHidden(false)
    
  }, [ 
      setMyPorter, 
      setFooterHidden, 
      myPorterCta, 
      headerTheme, 
      setHeaderDark, 
      setPageBackground, 
      touchColor, 
      setPageFeatureColor, 
      setFooterForms, 
      setFooterTheme 
    ]
  );
  

  return (
    <Layout>     
      
      {seo && <Seo {...seo} hidden={hidden}  />} 
      
      <ProjectBanner {...{heroVideo, heroImg, heroImgUrl, bannerStyle}} />      
      
      <ProjectInformation 
        {...{
          title, 
          client, 
          year, 
          description, 
          deliverables, 
          certificationOptions, 
          features, 
          sustainabilityOptions
        }}
      />      
      
      {pageModules && <ModuleZone {...pageModules} />}
      
      {assocProducts && assocProducts.length > 0 ? 
      <AssocProduct {...{assocProducts}} />
      :
        <ProjectCta />
      }
      
      {!hidden && <ProjectFooter {...pageContext} />}

    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    page: sanityProject(slug: { current: { eq: $slug } }) {
        hidden
        headerTheme {
            color {
                value
                title
            }
        }
        seo {
          ...SEO
        }
        touchColor {
          hex
        }
        description: _rawDescription(resolveReferences: {maxDepth: 10})        
        deliverables
        bannerStyle
        heroVideo {
          asset {
            url
          }
        }        
      heroImgUrl: heroImg {
        asset {
          url
        }
      }
      heroImg {
        ...ImageWithPreview
      }
      assocProducts {
        title
        sustainabilityOptions {
          title
          icon
        }
        certificationOptions {
          title
          icon
        }
        imageHero {
          asset {
              gatsbyImageData
          }
        }
        cardImages {
          asset {
              gatsbyImageData
          }
        }
        slug {
          current
        }
      }
        certificationOptions {
          title
          icon
        }
        sustainabilityOptions {
          title
          icon
        }
        features {
          title
        }
        title
        client
        year
        pageModules: modules {
            ...PageModules
        }        
    }
  }
`;
 