import React from 'react'
import ReactPlayer from 'react-player'
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import Image from "gatsby-plugin-sanity-image"

const ProjectBanner = ({ bannerStyle, heroVideo, heroImg, heroImgUrl }) => {

    const { scrollY } = useViewportScroll();
    const bannerParallax = useTransform(scrollY, [0, 1000], [0, 200]);
  
    return (
      <motion.div 
      style={{ y: bannerParallax }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 0.1, duration: 0.5 }}
      viewport={{ once: true }}
      >
        {bannerStyle === 'video' ? (
          <div className="w-full lg:h-screen">
          {heroVideo && heroVideo.asset.url && (
            <ReactPlayer url={heroVideo.asset.url} playing={true} muted={true} playsinline={true} loop={true} width='100%' height='100%' className="w-full h-auto lg:inset-0 lg:h-full lg:absolute bg_video" />          
          )}
          </div>
        ) : (
          <> 
          {/* Temporary image rendering */}
          {heroImgUrl && heroImgUrl.asset && (
             <img src={heroImgUrl.asset.url}  className="w-full h-auto lg:h-screen lg:object-cover" />
          )}                  
          {/* <Image             
             {...heroImg}             
             className="w-full h-auto lg:h-screen lg:object-cover"
           /> */}
          </>
        )}
        
      </motion.div>      
    )
}

export default ProjectBanner