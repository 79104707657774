import React from 'react'
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

const ProjectFooter = ({ next, prev }) => {
  return (
    <div className="project-footer grid-std grid-flow-row my-20 xl:my-40 gap-y-8">
        <div className="col-start-2 col-end-8 lg:col-end-13">
            <p className="text-sm lg:text-base">Return to</p>
            <Link className="trans-std xl:hover:opacity-100 mt-2 text-md lg:text-xl opacity-50" to="/projects">All Projects</Link>
        </div>
        <div className="col-start-2 col-end-8 lg:col-start-17 lg:col-end-26">
            <div className="mb-8">
            {next != null && (
                <>
                 <Link to={`/projects/${next.slug && next.slug.current && next.slug.current}`}  className="text-sm lg:text-base">
                    Next project
                </Link>
                <div className="hidden lg:flex related-item w-full justify-start relative mt-2">
                    <Link 
                        to={`/projects/${next.slug && next.slug.current && next.slug.current}`} 
                        className="flex items-baseline text-md lg:text-xl trans-std xl:hover:opacity-50"                        
                    >
                        {next.title && <p className="block title">{next.title}</p>}
                        {next.client && <p className="text-sm ml-4">{next.client}</p>}
                    </Link>
                    {next.touchImage && next.touchImage.asset && (
                        <div className="hidden lg:block absolute right-0 top-0 w-1/2 max-w-xs trans-std opacity-0">
                            <GatsbyImage                           
                                image={next.touchImage.asset.gatsbyImageData} 
                                alt={next.touchImage.asset.altText} 
                                className="aspect-square object-cover rounded-full" 
                            />   
                        </div>                     
                    )}
                </div>
                </>
            )}
            </div>
            <div>
            {prev != null && (
                <>
                <Link to={`/projects/${prev.slug && prev.slug.current && prev.slug.current}`} className="text-sm lg:text-base">
                    Previous project
                </Link>
                <div className="hidden lg:flex related-item w-full justify-start relative mt-2">
                    <Link 
                        to={`/projects/${prev.slug && prev.slug.current && prev.slug.current}`} 
                        className="flex items-baseline text-md lg:text-xl trans-std xl:hover:opacity-50"                        
                    >
                        {prev.title && <p className="block title">{prev.title}</p>}
                        {prev.client && <p className="text-sm ml-4">{prev.client}</p>}
                    </Link>
                    {prev.touchImage && prev.touchImage.asset && (
                        <div className="hidden lg:block absolute right-0 top-0 w-1/2 max-w-xs trans-std opacity-0">
                            <GatsbyImage                           
                                image={prev.touchImage.asset.gatsbyImageData} 
                                alt={prev.touchImage.asset.altText} 
                                className="aspect-square object-cover rounded-full" 
                            />   
                        </div>                     
                    )}
                </div>                
                </>
            )}
            </div>
        </div>
        
      </div>
  )
}

export default ProjectFooter