import React from 'react'
import { motion } from 'framer-motion';

//Components
import { PortableTextBlock } from "../ui/portableTextBlock"
import SustainabilityOption from "../ui/sustainabilityOption"
import FeatureTag from "../ui/featureTag"
import CertificationTag from "../ui/certificationTag"

const ProjectInformation = (
    { description,
        deliverables,
        certificationOptions,
        features,
        sustainabilityOptions,
        title,
        client,
        year
    }) => {
  return (
    <div className="bg-pp-grey60 relative z-10 grid-std gap-y-6 grid-flow-row pt-6 pb-12 lg:pt-6 lg:pb-24">
        <div className="col-start-2 col-end-8 lg:col-end-27">
          <motion.h1
          className="text-lg md:text-2xl lg:text-3xl mb-6"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}          
          viewport={{ once: true, amount: 1 }}
          >
              {title && title}
              {title && client && <br/>}
              {client && client}
          </motion.h1>
        </div>
        <motion.div
        className="col-start-2 col-end-8 lg:col-end-10 prose"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3}}
        viewport={{ once: true, amount: 0.5 }}
        >          
          {description && <PortableTextBlock text={description} />}
        </motion.div>
        <div className="col-start-2 col-end-8 lg:col-start-14 lg:col-end-27">
          <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-y-8">            
          {deliverables[0] && (
              <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.3}}
              viewport={{ once: true, amount: 0.5 }}
              >
                <p className="text-pp-grey30 mb-2">Deliverables</p>
                <ul className="list-disc list-inside leading-normal">
                  {deliverables.map((deliverable) => (
                    <>
                    {deliverable && <li>{deliverable}</li>}
                    </>
                  ))}
                </ul> 
              </motion.div>
              )}
              {year && (
              <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.3}}
              viewport={{ once: true, amount: 0.5 }}
              >                                  
                  <p className="text-pp-grey30 mb-2">Year</p>
                  <p className="mt-2">{year}</p>                    
              </motion.div> 
              )}           
          </div>
          {features[0] ? (
            <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3}}
            viewport={{ once: true, amount: 0.5 }}
            >
            <p className="text-pp-grey30 mt-12 mb-2">Features</p>
            <ul className="project-tag-list">
              {features.map((feature) => (
                <li><FeatureTag {...feature} /></li>
              ))}
            </ul>
            </motion.div>
          ) : ''}
          {sustainabilityOptions[0] ? (
            <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3}}
            viewport={{ once: true, amount: 0.5 }}
            >
            <p className="text-pp-grey30 mt-12 mb-2">Sustainability options</p>
            <ul className="project-tag-list">
              {sustainabilityOptions.map((option) => (<li><SustainabilityOption {...option} /></li>))}
            </ul>
            </motion.div>
          ) : ''}         
          {certificationOptions[0] ? (
            <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3}}
            viewport={{ once: true, amount: 0.5 }}
            >
            <p className="text-pp-grey30 mt-12 mb-2">Certification options</p>
            <ul className="project-tag-list">
              {certificationOptions.map((option) => (
                <li><CertificationTag {...option} /></li>
              ))}
            </ul>
            </motion.div>
          ) : ''}         
        </div>
      </div>
  )
}

export default ProjectInformation