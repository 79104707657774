import React from 'react'
import { Link } from "gatsby"

import { useSiteState } from '../../lib/siteContext';
import { useProjectCta } from '../../GraphQl/useProjectCta';

import { PortableTextBlock } from "../ui/portableTextBlock"

const ProjectCta = () => {

    const { setGlobalForm, setFormIsActive, formIsActive } = useSiteState();  

    const { ctaText, ctaButtonText, ctaForm } = useProjectCta()

  return (
    <section className="grid-std grid-flow-row my-20 xl:my-40 gap-y-8">
        <div className="col-start-2 col-end-8 lg:col-end-12">
                {ctaText && <PortableTextBlock className="prose max-w-none" text={ctaText} />}            
                {ctaButtonText && ctaForm && (
                     <button           
                     className="mt-6 inline-flex rounded-full border border-black bg-black text-white text-md py-4 px-6 cursor-pointer xl:transition xl:duration-300 xl:hover:opacity-70"          
                     onClick={() => {
                       setFormIsActive(!formIsActive)
                       setGlobalForm(ctaForm)
                     }}
                     onKeyDown={() => {
                       setFormIsActive(!formIsActive)
                       setGlobalForm(ctaForm)
                     }}
                   >
                       {ctaButtonText}
                   </button>
                )}            
        </div>
        <div className="col-start-2 col-end-8 lg:col-start-12 lg:col-end-26">
        
        </div>
      </section>
  )
}

export default ProjectCta